<template>
    <div>
        <CCard>
            <CCardHeader>
                <h3 class="float-left">Banners</h3>
                <router-link class="float-right" to="/ecobanners/create">
                    <CButton color="primary" class=" float-right" v-c-tooltip="'Crear nuevo registro'">
                        <i class="cib-addthis"></i>
                        Nuevo
                    </CButton>
                </router-link>
            </CCardHeader>

            <CCardBody>
                <!-- Buscar -->
                <div class="row">
                    <div class="col-md-6 col-md-offset-6">
                        <form class="form-inline">
                            <div class="form-group" style="float: right">
                                <select class="form-control" v-model="criterio">
                                    <option value="eco_banners.id">Codigo</option>
                                    <option value="eco_banners.titulo">Titulo</option>
                                </select>
                                <input
                                        type="text"
                                        v-model="buscar"
                                        @keyup.enter="listar(1, buscar, criterio)"
                                        class="form-control ml-2"
                                        placeholder="Texto a buscar"
                                />
                                <button type="button" @click="listar(1, buscar, criterio)"
                                        class="btn btn-primary  ml-2">
                                    <i class="fa fa-search" aria-hidden="true"></i> Buscar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- END: Buscar -->
                <br/>
                <br/>
                <br/>
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Banner</th>
                        <th scope="col">Título</th>
                        <th scope="col">Acción</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="valores in registros" :key="valores.id">
                        <th v-text="valores.id"></th>
                        <th>
                            <img :src="urlImage + 'eco/small_' + valores.imagen" style="width:75px"
                                 v-if="valores.imagen"/>
                            <i class="cil-image" v-if="!valores.imagen"></i>
                        </th>
                        <td v-text="valores.titulo"></td>
                        <td>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <router-link :to="{ name: 'Ver Banner', params: { id: valores.id } }">
                                    <button type="button" class="btn btn-secondary" v-c-tooltip="'Ver'">
                                        <i class="cil-zoom-in"></i>
                                    </button>
                                </router-link>

                                <router-link :to="{name: 'Borrar Banner', params: { id: valores.id }}">
                                    <button type="button" class="btn btn-secondary" v-c-tooltip="'Eliminar'">
                                        <i class="cil-trash"></i>
                                    </button>
                                </router-link>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!-- Paginación -->
                <div class="text-center">
                    <nav>
                        <ul class="pagination">
                            <li class="page-item" v-if="pagination.current_page > 1">
                                <a
                                        class="page-link"
                                        href="#"
                                        @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)"
                                >Ant</a>
                            </li>
                            <li
                                    class="page-item"
                                    v-for="page in pagesNumber"
                                    :key="page"
                                    :class="[page == isActived ? 'active' : '']"
                            >
                                <a
                                        class="page-link"
                                        href="#"
                                        @click.prevent="cambiarPagina(page,buscar,criterio)"
                                        v-text="page"
                                ></a>
                            </li>
                            <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                <a
                                        class="page-link"
                                        href="#"
                                        @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)"
                                >Sig</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <!-- END: Paginación -->
            </CCardBody>
        </CCard>
    </div>
</template>


<script>
import httpService from "@/services/httpService"

export default {
    data() {
        return {
            urlImage: process.env.VUE_APP_STORAGE,
            registros: [],
            pagination: {
                total: 0,
                current_page: 0,
                per_page: 0,
                last_page: 0,
                from: 0,
                to: 0
            },
            offset: 100,
            criterio: 'eco_banners.titulo',
            buscar: ''
        }
    },

    computed: {
        isActived: function () {
            return this.pagination.current_page
        },
        //Calcula los elementos de la paginación
        pagesNumber: function () {
            if (!this.pagination.to) {
                return []
            }
            let from = this.pagination.current_page - this.offset
            if (from < 1) {
                from = 1
            }
            let to = from + this.offset * 2
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page
            }
            let pagesArray = []
            while (from <= to) {
                pagesArray.push(from)
                from++
            }
            return pagesArray
        }
    },

    methods: {
        async listar(page, buscar, criterio) {
            let url = 'eco-banners?page=' + page + '&buscar=' + buscar + '&criterio=' + criterio
            let response = await httpService.get(url)
            let respuesta = response.data
            this.registros = respuesta.registros.data
            this.pagination = respuesta.pagination
        },
        cambiarPagina(page, buscar, criterio) {

            //Actualiza la página actual
            this.pagination.current_page = page;
            //Envia la petición para visualizar la data de esa página
            this.listar(page, buscar, criterio);
        },
    },
    mounted() {
        this.listar(1, this.buscar, this.criterio)
    }
}
</script>